<template>
  <b-row>
    <b-col cols="12">
      <register-table />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import RegisterTable from './RegisterTable.vue'

export default {
  components: {
    BRow,
    BCol,
    RegisterTable,

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
