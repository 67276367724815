<template>
  <b-card-code title="" no-body>
    <b-card-body
      ><h3>Total Register : {{ rows }}</h3>
    </b-card-body>

    <b-card-body>
      <b-row>
        <b-col md="3" xl="3" class="mb-1">
          <!-- basic -->
          <b-form-group label="Mentor Email" label-for="basicInput">
            <b-form-input
              id="basicInput"
               placeholder="mentor@email.com"
              v-model="search_mentor"
            />
          </b-form-group>
        </b-col>
        <b-col md="3" xl="3" class="mb-1">
          <!-- basic -->
          <b-form-group label="Status" label-for="status">
            <v-select
              v-model="selectedStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              multiple
              :options="statusList"
            />
          </b-form-group>
        </b-col>
       
        

        <b-col lg="3" md="3" class="mb-1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-primary"
            class="mt-0 mt-md-2"
            @click="refreshPage()"
          >
            <feather-icon icon="SearchIcon" class="mr-25" />
            <span>Search</span>
          </b-button>
        </b-col>
        <b-col lg="2" md="2" class="mb-1">
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="primary"
            class="mt-0 mt-md-2"
            @click="registerPage()"
          >
            <feather-icon icon="EditIcon" class="mr-25" />
            <span>Input New</span>
          </b-button>
        </b-col>
       
      </b-row>

    
    </b-card-body>
    

    <!-- table -->
    <b-table
      ref="selectableTable"
      selectable
      :select-mode="selectMode"
      :items="items"
      :fields="fields"
      responsive
      class="mb-0"
      @row-selected="onRowSelected"
    >
      <template #cell(mentor)="data">
        <p>{{data.value.nama_panjang }}</p>
        
      </template>
      <template #cell(date)="data">
        <p>{{data.value}}</p>
        
      </template>
      <template #cell(member.status)="data">
        <b-badge :variant="member_status[1][data.value]">
          {{ member_status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
     
      
      <template #cell(action)="data">
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click=" detailRegister(data.item)">
            <feather-icon icon="UserIcon" size="16" />
            <span class="align-middle ml-50">Detail</span>
          </b-dropdown-item>
          <b-dropdown-item @click="showSequenceForm(data.item)">
            <feather-icon icon="ChevronsUpIcon" size="16" />
            <span class="align-middle ml-50">Sequence</span>
          </b-dropdown-item>
          <b-dropdown-item @click="acceptRegis(data.item)">
            
            <feather-icon icon="UserCheckIcon" size="16" />
            <span class="align-middle ml-50">Accept</span>
          </b-dropdown-item>
          <b-dropdown-item @click="rejectRegis(data.item)">
            <feather-icon icon="UserXIcon" size="16" />
            <span class="align-middle ml-50">Reject</span>
          </b-dropdown-item>
          <b-dropdown-item @click="hideRegis(data.item)">
            <feather-icon icon="EyeIcon" size="16" />
            <span class="align-middle ml-50">Hide</span>
          </b-dropdown-item>
        
        </b-dropdown>
      </template>
      <template #cell(created_at)="data">
        <p>{{convertISODateTime(data.value)}}</p>
        
      </template>
      <template #cell(updated_at)="data">
        <p>{{convertISODateTime(data.value)}}</p>
        
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <div></div>
      <!--pagination -->
      <div class="demo-inline-spacing pt-0">
        <b-pagination
          v-model="currentPage"
          hide-goto-end-buttons
          :total-rows="rows"
          :per-page="perPage"
        />
      </div>
    </b-card-body>
  

    <b-modal id="modal-sequence" hide-footer>
      <template #modal-title> Input sequence! </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
     
          <!-- basic -->
          
          <b-form-input id="number" type="number"  v-model="sequence"></b-form-input>


      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        :disabled="selectedType == null"
        @click="submitSequence()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>
    <b-modal id="modal-reject-regis" hide-footer>
      <template #modal-title> Input description for rejection! </template>
      <div class="d-block text-center">
        <!-- <p>Apakah anda yakin? Jika ya silahkan input deskripsi.</p> -->
      </div>
     
          <!-- basic -->
          
          <b-form-textarea
            id="textarea"
            v-model="rejectDescription"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
            required
          ></b-form-textarea>


      <b-button
        class="mt-3"
        variant="outline-primary"
        block
        :disabled="selectedType == null"
        @click="submitRejection()"
      >
        <feather-icon icon="SendIcon" /> Submit
      </b-button>
    </b-modal>

    <b-modal
      id="modal-detail-regis"
      ok-only
      ok-title="Accept"
      centered
      hide-footer
      size="xl"
      title="Detail Mentor Register"
    >
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Account</span>
            </template>
            
           
            <template>
              <b-card
                img-alt="Profile Cover Photo"
                img-top
                class="card-profile"
              >
                <div class="profile-image-wrapper">
                  <div class="profile-image p-0">
                    <b-avatar
                      size="114"
                      variant="light"
                      :src="getPhoto()"
                    />
                  </div>
                </div>
                <h3 >{{this.detailRegis.profile.nama_panjang}}</h3>
                <h6 class="text-muted">
                  {{this.detailRegis.profile.occupation}}
                </h6>
                <b-badge
                  class="profile-badge"
                  :variant="this.status[1][this.detailRegis.status] "
                >
                  {{this.status[0][this.detailRegis.status] }}
                </b-badge>
                <hr class="mb-2">

               
                <!--/ follower projects rank -->
                
                  <b-row>
                    <b-col cols="6">
                      <b-row>
                        <b-col >
                          <h6 class="text-muted text-left font-weight-bolder">Email</h6>
                          <p class="text-left mb-0">{{this.detailRegis.member.email}}</p>
                        </b-col>
                        <b-col>
                          <h6 class="text-muted text-left font-weight-bolder"> Bio</h6>
                          <p class=" text-left mb-0">{{this.detailRegis.profile.bio}}</p>
                        </b-col>
                      </b-row>
                      <br>
                      <b-row>
                        <b-col>
                          <h6 class="text-muted text-left font-weight-bolder">Pendidikan</h6>
                          <p class=" text-left mb-0">{{this.detailRegis.education.fakultas.nama_fakultas + " " + this.detailRegis.education.tahun_masuk}}</p>
                        </b-col>
                        <b-col>
                          <h6 class="text-muted text-left font-weight-bolder">Title</h6>
                          <p class=" text-left mb-0">{{this.detailRegis.profile.occupation}}</p>
                        </b-col>
                      </b-row>
                      <br>
                      <b-row >
                        <b-col>
                          <h6 class="text-muted text-left font-weight-bolder">Status</h6>
                          <p class=" text-left mb-0">{{this.status[0][this.detailRegis.status] + ' At ' + convertISODateTime(this.detailRegis.updated_at) }} </p>
                        </b-col>
                        <b-col v-if="this.detailRegis.status==2">
                          <h6 class="text-muted text-left font-weight-bolder">Reason to Reject</h6>
                          <p class=" text-left mb-0">{{this.detailRegis.rejection ? this.detailRegis.rejection.description : null}}</p>
                        </b-col>
                       
                      </b-row>
                    </b-col>
                    <b-col class="text-left">
                      
                      <h6 class="text-muted text-left font-weight-bolder">Social Media</h6>

                      <tr><td><a :href="this.detailRegis.profile.link_linkedin" target="_blank">- LinkedIn</a></td></tr>
                      <tr><td><a :href="this.detailRegis.profile.link_ig" target="_blank">- Instagram</a></td></tr>
                      <tr><td><a :href="this.detailRegis.profile.link" target="_blank">- Web</a></td></tr>
                      
                    
                    </b-col>
                    <b-col class="text-left">
                      
                      <h6 class="text-muted text-left font-weight-bolder">Topic</h6>
                      <tr v-for="(tp,index) in detailRegis.topic" :key="index"><td>- {{ tp.name }}</td></tr>
                    
                    </b-col>
                    <b-col class="text-left">
                      
                      <h6 class="text-muted text-left font-weight-bolder">Minat</h6>
                      <tr v-for="(mt,index) in detailRegis.minat" :key="index"><td>- {{ mt.topik }}</td></tr>
                    
                    </b-col>
                    
                  </b-row>
                  
              </b-card>
              <template>
                      <div class="demo-inline-spacing justify-content-end">
                        
                        
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          variant="success"
                          @click="acceptRegis(detailRegis)"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Accept</span>
                        </b-button>
                        <b-button
                          variant="danger"
                          @click="rejectRegis(detailRegis)"
                        >
                          <feather-icon
                            icon="XIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Reject</span>
                        </b-button>
                        
                      </div>
                    </template>
            </template>
            
          </b-tab>
       
          <b-tab disabled>
            <template #title>
              <feather-icon icon="EyeOffIcon" />
              <span>Mentoring Session</span>
            </template>

            <b-card-text>
              Halvah dessert fruitcake toffee oat cake tart oat cake topping jelly beans. Pudding sweet pie pastry lemon drops jujubes danish pie gingerbread. Liquorice powder wafer.
            </b-card-text>
          </b-tab>
        
        </b-tabs>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody, BPagination, BFormTextarea,
  BButtonToolbar, BButtonGroup, BDropdown, BDropdownItem, BFormInput, BRow, BCol, BModal,
  BTabs, BTab, BCardText,
  BMedia, BImg, BMediaAside, BMediaBody,
  BCard
} from 'bootstrap-vue'
import API from '@/api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import moment from 'moment'

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BModal,
    BFormTextarea,
    BTabs, BTab, BCardText,
    BMedia, BImg, BMediaAside, BMediaBody,
    BCard
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 50,
      modes: ['multi', 'single', 'range'],
      fields: ['sequence',
        { key: 'profile.nama_panjang', label: 'Nama' },
        { key: 'member.email', label: 'Email' },
        { key: 'member.status', label: 'Member Status' },
        { key: 'status', label: 'Mentor Status' },
         'action', 'created_at', 'updated_at'],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        0: 'Registered', 1: 'Accepted', 2: "Rejected", 9:"Hide"
      },
      {
        0: 'light-primary', 1: 'light-success', 2: 'light-danger', 9: 'light-warning',
        }],
      member_status: [{
        1: 'Pre Register', 2: 'Registered', 3: 'Verified', 4: 'Unverified', 5: 'Active',
      },
      {
        1: 'light-info', 2: 'light-info', 3: 'light-primary', 4: 'light-danger', 5: 'light-success',
      }],
      selectMode: 'multi',
      selected: [],

      selectedJurusans: [],
      selectedFakultas: [],
      fakultass: [],
      jurusans: [],
      search_mentor: null,
      email: null,
      rejectDescription: null,
      detailRegis: null,
      sequence: 0,
      profile:null,
      photo:null,
      typeList: [
        { id: 2, name: "Can't find" },
        { id: 3, name: 'Input wrong data' }
      ],
      selectedType: { id: 2, name: "Can't find" },
      statusList: [
        { id: 0, name: 'Registered' },
        { id: 1, name: 'Accepted' },
        { id: 2, name: "Rejected" }
      ],

      selectedStatus: [],

    }
  },
  methods: {
    onRowSelected(items) {
      this.selected = items
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    refreshPage() {
      if (this.currentPage == 1) {
        this.getRegister()
      }
      else {
        this.currentPage = 1
      }

    },
    async getRegister() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        var statusIds = this.selectedStatus.length > 0 ? this.selectedStatus.map(function (item) { return item['id'] }) : null;

        const form = {
          limit: this.perPage,
          page: this.currentPage,
          status: statusIds ? statusIds : null,
          email: this.search_mentor

        }
        const { data } = await API.mentorship.registerList(form)
        console.log(data.data)
        this.data_table = data.data
        this.items = data.data
        this.rows = data.total
        this.detailRegis = this.items[0]

        this.handleMsgSuccess('Berhasil tampilkan data register')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async submitSequence() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          sequence: this.sequence,
          id:this.detailRegis.member_id
        }
        const { data } = await API.mentorship.sequence(form)
        this.sequence = null
        this.handleMsgSuccess('Berhasil update sequence')
        this.$bvModal.hide("modal-sequence")
        this.getRegister()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async submitRejection() {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          description: this.rejectDescription,
          id:this.detailRegis.member_id
        }
        const { data } = await API.mentorship.rejectRegis(form)

        this.rejectDescription = null
      

        this.handleMsgSuccess('Berhasil reject user')
        this.$bvModal.hide("modal-reject-regis")
        this.getRegister()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async acceptRegis(item) {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          id: item.member_id
        }
        const { data } = await API.mentorship.acceptRegis(form)
        this.handleMsgSuccess('Berhasil rubah status')
        this.getRegister()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async hideRegis(item) {

      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        const form = {
          id: item.member_id
        }
        const { data } = await API.mentorship.hideRegis(form)
        this.handleMsgSuccess('Berhasil rubah status')
        this.getRegister()
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Terjadi kesalahan silahkan coba lagi!')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    
    showSequenceForm(item) {
      this.detailRegis = item
      this.sequence = null
      console.log(this.detailRegis)
      this.$bvModal.show("modal-sequence")

    },
    rejectRegis(item) {
      this.detailRegis = item
      this.rejectDescription = ""
      console.log(this.detailRegis)
      this.$bvModal.show("modal-reject-regis")

    },
    async detailRegister(item) {
      this.detailRegis = item
      this.photo = item.profile.photo
      //this.rejectDescription = this.detailRegis.register.rejectDescription
   
      this.$bvModal.show("modal-detail-regis")

    },
    registerPage() {
      router.push('register-input')
    },
    convertISODateTime(isoStr1){
      // ✅ Create a Date object (setting the date to local time)
      //const date2 = new Date(isoStr1.slice(0, -1));
      return moment(isoStr1).toString();
      return date2

    },

    getPhoto() {
      return this.photo
    }



  },
  mounted() {
    this.getRegister()
  },
  watch: {
    currentPage() {
      this.getRegister()
    },

  }
}
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
